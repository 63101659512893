import React, { useEffect } from "react";
import AuthStore from "../../../stores/AuthStore";
import { AppLayout, DashboardLayout } from "../shared/layout";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";

export function HomeScreen() {
  useEffect(() => {});
  return (
    <div>
      <AppLayout title="Dashboard">
        <Button
          asChild
          className="mr-4 mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded h-64 w-64"
        >
          <Link to="/invoices-all">All Invoices</Link>
        </Button>
        <Button
          asChild
          className="mr-4 mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-64 w-64"
        >
          <Link to="/invoices-operations">Invoices Operations</Link>
        </Button>
      </AppLayout>
    </div>
  );
}
