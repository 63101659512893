import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { NavbarApp } from "../../../components";
import { getProfile } from "../../../data";
import AuthStore from "../../../stores/AuthStore";
import { cn } from "@/lib/utils";

export const AppLayout = (props) => {
  const [fistTimeForMount, setFistTimeForMount] = useState(true);
  const { data, isSuccess } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });
  useEffect(() => {
    //Todo: for permission control this code writed here, use effect is not good place for this code

    if (isSuccess) {
      if (data != null) {
        if (fistTimeForMount) {
          console.log("isSuccess b", isSuccess);
          AuthStore.setProfile(data.data.user);
        } else {
          console.log("isSuccess c", isSuccess);
        }

        setFistTimeForMount(false);
      }
    }
  }, [fistTimeForMount, isSuccess, data]);

  return (
    <div>
      <NavbarApp />
      <header
        className={cn(
          props.fullscreen ? "w-full" : "max-w-7xl",
          " mx-auto p-4  lg:flex lg:items-center lg:justify-between "
        )}
      >
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {props.title}
          </h2>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4 gap-2">
          {props.actions != null
            ? props.actions.map((item) =>
                item.isHide ? null : (
                  <button
                    key={item.name}
                    className={"font-bold py-2 px-4 rounded " + item.color}
                    onClick={item.onClick}
                  >
                    {item.name}
                  </button>
                )
              )
            : ""}
        </div>
      </header>
      <main className="w-full">
        <div
          className={cn(props.fullscreen ? "w-full mx-5" : "max-w-7xl mx-auto")}
        >
          {props.loading && (
            <div>
              <h1>Loading...</h1>
            </div>
          )}
          {props.children}
        </div>
      </main>
    </div>
  );
};
