import { Badge } from "../ui/badge";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Card, CardContent } from "../ui/card";

export function InvoiceDetailItem({
  label,
  formId,
  ourValue,
  ocrValue,
  onAccept,
  onInputChanged,
  isApproved,
  hideClient,
  hideMatch,
}) {
  return (
    <Card className="flex items-center  p-0">
      <CardContent className="flex item-center justify-between p-1 w-full">
        <div className=" flex flex-col w-full  ">
          {!hideClient && (
            <div className="flex  gap-3 items-center">
              <div className="flex  items-center gap-1  ">
                <Badge className="w-[55px]">Client</Badge>
                <Label className="w-[100px] block text-gray-700 text-sm font-bold ">
                  {label}
                </Label>
              </div>
              <div className="flex w-full  ">
                <Label className="p-2 px-3">{ourValue}</Label>
              </div>
            </div>
          )}

          <div className="flex gap-3 items-center ">
            <div className="flex  items-center gap-1   ">
              <Badge variant="destructive" className="w-[55px]">
                Ocr
              </Badge>
              <Label className="w-[100px]  block text-gray-700 text-sm font-bold  ">
                {label}
              </Label>
            </div>

            <div className="flex w-full">
              <input
                className="flex-1 shadow appearance-none border rounded text-sm p-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                onChange={(e) => {
                  onInputChanged(e.target.value);
                  console.log(e.target.value);
                }}
                defaultValue={ocrValue}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1 items-center m-1 justify-between">
          {!hideMatch &&
            (ocrValue === ourValue ? (
              <Badge variant="success">Match</Badge>
            ) : (
              <Badge variant="destructive">Not </Badge>
            ))}

          {isApproved ? (
            <Badge variant="success">Approved</Badge>
          ) : (
            <Button type="button" onClick={onAccept}>
              Accept
            </Button>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
